import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

export default new VueRouter({
  mode:'hash',
  base:process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
  	return { x: 0, y: 0}
  },
  routes:[
    {
      path:'/',
      component:()=>import('@/views/wrap'),
      children:[{
        path:'/',
        component:()=>import('@/views/index'),
        meta:{
          title:'首页',
          index:0
        }
      }]
    },
	{
	  path:'/carTypeIntro',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carTypeIntro',
	    component:()=>import('@/views/carTypeIntro'),
	    meta:{
	      title:'车型介绍',
	      index:1
	    }
	  }]
	},
	{
		path:'/vipCarInfo',
		component:()=>import('@/views/wrap'),
		children:[{
			path:'/vipCarInfo',
			component:()=>import('@/views/vipCarInfo'),
			meta:{
				title:'VIP车型',
				index:8
			}
		}]
	},
	{
	  path:'/tools',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/tools',
	    component:()=>import('@/views/tools'),
	    meta:{
	      title:'实用工具',
	      index:3
	    }
	  }]
	},
	{
	  path:'/carVideo',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carVideo',
	    component:()=>import('@/views/carVideo'),
	    meta:{
	      title:'直播看车',
	      index:2
	    }
	  }]
	},
	{
	  path:'/about',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/about',
	    component:()=>import('@/views/about'),
	    meta:{
	      title:'联系我们',
	      index:4
	    }
	  }]
	},
	{
	  path:'/about-details',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/about-details',
	    component:()=>import('@/views/about-details'),
	    meta:{
	      title:'关于我们简介',
	      index:4
	    }
	  }]
	},
	{
	  path:'/contact',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/contact',
	    component:()=>import('@/views/contact'),
	    meta:{
	      title:'关于我们',
	      index:4
	    }
	  }]
	},
	{
	  path:'/join',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/join',
	    component:()=>import('@/views/join'),
	    meta:{
	      title:'联系我们-公司招聘',
	      index:4
	    }
	  }]
	},
	{
	  path:'/join-details',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/join-details',
	    component:()=>import('@/views/join-details'),
	    meta:{
	      title:'联系我们-公司招聘详情',
	      index:4
	    }
	  }]
	},
	{
	  path:'/feedback',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/feedback',
	    component:()=>import('@/views/feedback'),
	    meta:{
	      title:'联系我们-留言反馈',
	      index:4
	    }
	  }]
	},
	{
	  path:'/carTypeVs',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carTypeVs',
	    component:()=>import('@/views/carTypeVs'),
	    meta:{
	      title:'车型对比',
	      index:''
	    }
	  }]
	},
	{
	  path:'/news',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/news',
	    component:()=>import('@/views/news'),
	    meta:{
	      title:'新闻资讯',
	      index:''
	    }
	  }]
	},
	{
	  path:'/news-details',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/news-details',
	    component:()=>import('@/views/news-details'),
	    meta:{
	      title:'新闻资讯-详情',
	      index:''
	    }
	  }]
	},
	{
	  path:'/carType-details',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carType-details',
	    component:()=>import('@/views/carType-details'),
	    meta:{
	      title:'车型详情',
	      index:1
	    }
	  }]
	},
		{
			path:'/tool-details',
			component:()=>import('@/views/wrap'),
			children:[{
				path:'/tool-details',
				component:()=>import('@/views/tool-details'),
				meta:{
					title:'实用工具详情',
					index:1
				}
			}]
		},
	{
	  path:'/carType-video-list',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carType-video-list',
	    component:()=>import('@/views/carType-video-list'),
	    meta:{
	      title:'车型视频',
	      index:1
	    }
	  }]
	},
	{
	  path:'/carType-GNvideo-list',
	  component:()=>import('@/views/wrap'),
	  children:[{
	    path:'/carType-GNvideo-list',
	    component:()=>import('@/views/carType-GNvideo-list'),
	    meta:{
	      title:'功能视频',
	      index:1
	    }
	  }]
	},

		{
			path:'/login',
			component:()=>import('@/views/login'),
		},

  ]
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
