<template>
	<div class="ft20">
		<div class="filter-bar">
			<div class="el-border-bottom pb30 pl30 pr30 pt30">
				<el-row>
					<el-col :span="3" style="cursor: pointer;" @click.native="handleDefaultSort">
						<span>综合排序</span>
					</el-col>
					<el-col :span="3">
						<div class="hover" @click="changeFilter('selectBrandStatus')">
							<span>品牌</span>
							<i class="el-icon-caret-top red" v-if="selectBrandStatus"></i>
							<i class="el-icon-caret-bottom" v-else></i>
						</div>
					</el-col>
					<el-col :span="3">
						<div class="hover" @click="changeFilter('selectCountryStatus')">
							<span>国别</span>
							<i class="el-icon-caret-top red" v-if="selectCountryStatus"></i>
							<i class="el-icon-caret-bottom" v-else></i>
						</div>
					</el-col>
					<!--<el-col :span="3">-->
						<!--<div class="hover" @click="changeFilter('selectPaiLiangStatus')">-->
							<!--<span>排量</span>-->
							<!--<i class="el-icon-caret-top red" v-if="selectPaiLiangStatus"></i>-->
							<!--<i class="el-icon-caret-bottom" v-else></i>-->
						<!--</div>-->
					<!--</el-col>-->
					<!--<el-col :span="3">-->
						<!--<div class="hover" @click="changeFilter('selectColorStatus')">-->
							<!--<span>颜色</span>-->
							<!--<i class="el-icon-caret-top red" v-if="selectColorStatus"></i>-->
							<!--<i class="el-icon-caret-bottom" v-else></i>-->
						<!--</div>-->
					<!--</el-col>-->
				</el-row>
			</div>
			<div class="el-box">
				<!-- 品牌 -->
				<div class="brand-box" v-if="selectBrandStatus">
					<div class="ft20" v-if="brandStatus">
						<div class="title pt20  pl30 pr30 el-border-bottom pb20" @click="handleCancle" style="cursor: pointer;">不限</div>
						<div v-for="(item,index) in brandList" :key="index" @click="handleBrand(item.code, item.name)" class="title pt20 over hover pl30 pr30 el-border-bottom pb20 over">
							<el-image style="width: 58px;display: inline-block;vertical-align: middle;float: left;" fit="cover" :src="item.icon"></el-image>
							<span class="ml40 fl">{{item.name}}</span>
							<i class="el-icon-arrow-right fr"></i>
						</div>
					</div>
					<div class="ft20" v-else>
						<div class="title pt20  pl30 pr30 el-border-bottom pb20 hover" @click="brandStatus=true">
							<i class="el-icon-arrow-left"></i>
							<span class="ml20">{{brandName}} - </span>
							<span class="red">{{seriesName}}</span>
						</div>
						<div v-for="(item,index) in seriesList" :key="index" @click="handleSeries(item.code, item.name)" class="title pt20 over hover pl30 pr30 el-border-bottom pb20 over">
							<span class="fl">{{item.name}}</span>
							<i class="el-icon-arrow-right fr"></i>
						</div>
					</div>
				</div>
				<!-- 国别 -->
				<!-- <div class="brand-box" v-if="selectCountryStatus">
					<div class="ft20">
						<div v-for="(item,index) in 10" :key="index" @click="selectCountryStatus=false" class="title pt20 over hover pl30 pr30 el-border-bottom pb20 over">
							<span class="fl">日本</span>
							<i class="el-icon-arrow-right fr"></i>
						</div>
					</div>
				</div> -->
				<div class="brand-box filer-tag" v-if="selectCountryStatus">
					<div class="ft20 over pb40 pl30 pr30">
						<el-row :gutter="40">
							<el-col :span="4" v-for="(item,index) in countryValue" :key="index">
								<div @click="selectCountryChange(index)" :class="item.check?'selected':''" class="item ft18 flex hover">
									{{item.name}}
								</div>
							</el-col>
						</el-row>
						<el-button type="primary" @click="handleCountrySubmit" style="width: 60%;margin: 60px auto 0;display: block;height: 50px;border-radius: 100px;" round>
							<span class="ft20">确定</span>
						</el-button>
					</div>
				</div>
				<!-- 排量 -->
				<div class="brand-box filer-tag" v-if="selectPaiLiangStatus">
					<div class="ft20 over pb40 pl30 pr30">
						<el-row :gutter="40">
							<el-col :span="4" v-for="(item,index) in paiLingValue" :key="index">
								<div @click="selectPaiLiangChange(index)" :class="item.check?'selected':''" class="item ft18 flex hover">
									{{item.name}}
								</div>
							</el-col>
						</el-row>
						<el-button type="primary" @click="selectPaiLiangStatus=false" style="width: 60%;margin: 60px auto 0;display: block;height: 50px;border-radius: 100px;" round>
							<span class="ft20">确定</span>
						</el-button>
					</div>
				</div>
				<!-- 颜色 -->
				<div class="brand-box filer-tag" v-if="selectColorStatus">
					<div class="ft20 over pb40 pl30 pr30">
						<el-row :gutter="40">
							<el-col :span="4" v-for="(item,index) in colorValue" :key="index">
								<div @click="selectColorChange(index)" :class="item.check?'selected':''" class="item flex ft18 hover">
									{{item.name}}
								</div>
							</el-col>
						</el-row>
						<el-button type="primary" @click="selectColorStatus=false" style="width: 60%;margin: 60px auto 0;display: block;height: 50px;border-radius: 100px;" round>
							<span class="ft20">确定</span>
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="filter-bg" @click="changeFilter" v-if="selectBrandStatus || selectCountryStatus || selectPaiLiangStatus || selectColorStatus"></div>
	</div>
</template>

<script>
	import { findCountry, findBrand, findSeries} from '@/api/index.js'
	export default{
		data(){
			return{
				brandStatus:true,
				selectBrandStatus:false,
				selectCountryStatus:false,
				countryValue:[
					{
						code: '',
						name: '不限',
						check:false
					}
				],
				brandName: '',
				seriesName: '',
				brandList: [],
				seriesList: [],
				selectPaiLiangStatus:false,
				paiLingValue:[{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false},{name:'3956cc',check:false}],
				selectColorStatus:false,
				colorValue:[{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false},{name:'红色',check:false}],
			}
		},
		created(){
			this.getBrandList()
			this.getCountryList()
		},
		methods:{
			handleDefaultSort(){
				this.countryValue.forEach(item=>{
					item.check = false
				})
				this.selectCountryStatus=false
				this.brandName = ''
				this.seriesName = ''
				this.brandStatus = true
				this.$emit('update:series','')
				this.$emit('update:countryCode',[])
				this.$emit('refreshDataList')
			},
			selectPaiLiangChange(index){
				this.paiLingValue[index].check = !this.paiLingValue[index].check;
			},
			selectColorChange(index){
				this.colorValue[index].check = !this.colorValue[index].check;
			},
			selectCountryChange(index){
				if(index == 0){
					this.countryValue.forEach((item,idx)=>{
						if(idx > 0){
							this.countryValue[idx].check = false
						}
					})
				}
				this.countryValue[index].check = !this.countryValue[index].check;
			},
			changeFilter(tag){
				this.selectBrandStatus = false;
				this.selectCountryStatus = false;
				this.selectPaiLiangStatus = false;
				this.selectColorStatus = false;
				this[tag]=!this[tag]
			},

			getCountryList(){
				findCountry().then(res=>{
					if(res.code === 200){
						res.data.forEach(item=>{
							this.countryValue.push({
								code: item.code,
								name: item.name,
								check:false
							})
						})
					}
				})
			},

			getBrandList(){
				findBrand().then(res=>{
					if(res.code === 200){
						this.brandList = res.data;
					}
				})
			},
			handleBrand(code, name){
				this.brandStatus=false
				this.brandName = name
				this.getSeriesList(code)
			},
			getSeriesList(code){
				findSeries(code).then(res=>{
					if(res.code === 200){
						this.seriesList = res.data;
						if(this.seriesList && this.seriesList.length > 0){
							this.seriesName = this.seriesList[0].name
						}
					}
				})
			},
			handleSeries(code, name){
				this.seriesName = name;
				this.$emit('update:series',code)
				this.$emit('refreshDataList')
				this.selectBrandStatus=false
			},
			handleCancle(){
				this.$emit('update:series','')
				this.$emit('refreshDataList')
				this.selectBrandStatus=false
				this.brandStatus=false
			},
			handleCountrySubmit(){
				let countryCode = []
				this.countryValue.forEach(item=>{
					if(item.check && item.code != ''){
						countryCode.push(item.code)
					}
				})
				if(countryCode && countryCode.length > 0){
					this.$emit('update:countryCode',countryCode)
				}else{
					this.$emit('update:countryCode',[])
				}
				this.$emit('refreshDataList')
				this.selectCountryStatus=false
			}
		}
	}
</script>

<style scoped="scoped">
	.el-box{
		max-height: 700px;
		overflow-y: auto;
	}
	.filter-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 7;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
	}
	.filter-bar{
		position: sticky;
		width: 100%;
		box-sizing: border-box;
		background-color: #FFFFFF;
		border-radius: 10px;
		border: 1px solid #F1F1F1;
		z-index: 8;
		overflow: hidden;
	}
	.filer-tag .item{
		padding: 15px 0px;
		border-radius: 10px;
		background-color: #f3f3f3;
		float: left;
		margin: 20px 0 0;
		width: 100%;
		text-align: center;
	}
	.filer-tag .item.selected{
		background-color:#f0d3d5 ;
	}
</style>
